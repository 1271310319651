.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loginBackground {
  background-image: url("./assets/img/background.jpg"); /* Update with your image path */
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  min-height: 100vh; /* Full height of the viewport */
  width: 100%; /* Full width */
  position: relative; /* Ensure the position is relative for any absolute children */
}

@media (max-width: 576px) {
  .loginContainer {
    max-width: 90%; /* Fixed width for small devices */
  }
}

@media (min-width: 576px) {
  .loginContainer {
    max-width: 500px; /* Fixed width for medium devices */
  }
}

@media (min-width: 768px) {
  .loginContainer {
    max-width: 600px; /* Fixed width for larger devices */
  }
}

.button {
  background-color: #ffc107; /* Change this to the color you choose */
  color: #000; /* Text color */
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #e0a800; /* Darker shade for hover effect */
}
